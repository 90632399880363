<template>
  <div class="p-fluid grid">
    <div class="field col-12 md:col-2">
      <span class="formulario-title">ASIGNADOS</span>
      <ul class="list-none p-0 m-0 text-center">
        <li
          v-for="(frmpredefinido, index) in listado_frmpredefinidos"
          :key="index"
          class="formulario-item"
        >
          <span
            @click="mostrarFormularioSeleccionado(frmpredefinido)"
            class="formulario-name"
          >
            {{ frmpredefinido.nombre_formulario }}
          </span>
        </li>
      </ul>
      <span class="formulario-title">NO ASIGNADOS</span>
      <ul class="list-none p-0 m-0 text-center">
        <li
          v-for="(formulario, index) in listado_formularios"
          :key="index"
          class="formulario-item"
        >
          <span
            @click="crearNuevoFormulario(formulario)"
            class="formulario-name"
          >
            {{ formulario.titulo }}
          </span>
        </li>
      </ul>
    </div>
    <div class="field col-12 md:col-10" v-if="formularioSelected">
      <div class="grid">
        <div class="p-field col-12 md:col-5">
          <span
            ><strong>MÉDICO: </strong>
            {{ formularioSelected.nombre_medico }}</span
          >
        </div>
        <div class="p-field col-12 md:col-7">
          <span
            ><strong>T&Iacute;TULO: </strong>
            {{ formularioSelected.nombre_formulario }}</span
          >
        </div>
        <div class="field col-12 md:col-12">
          <quill-editor
            ref="quillEditor"
            v-model:content="formularioSelected.contenido"
            :options="opcionesQuill"
          />
        </div>
      </div>
    </div>
    <div class="field col-12 md:col-10" v-if="mostrarNuevoFormulario">
      <div class="grid">
        <div class="p-field col-12 md:col-5">
          <span><strong>M&Eacute;DICO: </strong></span>
          <Dropdown
            v-model="medicoSelected"
            :options="medicos"
            optionLabel="nombre_completo"
            placeholder="Seleccione un médico"
            optionValue="id"
          />
        </div>
        <div class="p-field col-12 md:col-7">
          <span
            ><strong>T&Iacute;TULO: </strong> {{ nuevoFormulario.titulo }}</span
          >
        </div>
        <div class="field col-12 md:col-12">
          <quill-editor
            ref="quillEditor"
            v-model:content="nuevoFormulario.contenido"
            :options="opcionesQuill"
          />
        </div>
      </div>
    </div>
    <div
      class="field col-12 md:col-12"
      v-if="formularioSelected || mostrarNuevoFormulario"
    >
      <div class="p-fluid formgrid grid justify-content-end mt-1">
        <div class="field col-12 md:col-2"></div>
        <div class="field col-12 md:col-6" v-if="mostrarNuevoFormulario">
          <div class="p-inputgroup">
            <span class="formulario-title">FIRMA: </span>
            <canvas
              ref="canvas"
              height="90"
              width="250"
              class="canvas"
            ></canvas>
            <Button
              v-if="mostrarNuevoFormulario"
              icon="pi pi-refresh"
              class="p-button-lg p-button-warning p-button-text"
              style="border: 1px solid #002236"
              v-tooltip.top="'Limpiar firma'"
              @click="limpiarFirma()"
            />
          </div>
        </div>
        <div class="field col-12 md:col-6" v-if="formularioSelected">
          <div class="p-inputgroup">
            <span class="formulario-title">FIRMA: </span>
            <img
              :src="formularioSelected.firma"
              alt="Firma"
              height="100"
            /><br />
          </div>
        </div>
        <div class="field col-12 md:col-2">
          <Button
            v-if="!mostrarNuevoFormulario"
            label="IMPRIMIR"
            icon="pi pi-print"
            class="p-button-lg p-button-danger"
            @click="imprimirFormulario()"
            :loading="imprimiendo"
          />
        </div>
        <div class="field col-12 md:col-2" v-if="mostrarNuevoFormulario">
          <Button
            label="GUARDAR"
            icon="pi pi-save"
            class="p-button-lg p-button-info"
            @click="guardarFrm()"
          />
          <!--  :loading="guardando" -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlanTratamientoService from "@/service/PlanTratamientoService";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import SignaturePad from "signature_pad";
import { nextTick } from "vue";

export default {
  emits: ["actualizarListado"],
  components: {
    QuillEditor,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    datos_frmprefedinidos: {
      type: Array,
      default() {
        return [];
      },
    },
    formulario_predefinidos: {
      type: Array,
      default() {
        return [];
      },
    },
    cliente_id: {
      type: Number,
      default: 0,
    },
    medicos: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      mostrarModal: this.show,
      listado_frmpredefinidos: this.datos_frmprefedinidos,
      listado_formularios: this.formulario_predefinidos,
      data_cliente_id: this.cliente_id,
      listado_medicos: this.medicos,
      formularioSelected: null,
      opcionesQuill: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ header: 1 }, { header: 2 }],
            [{ header: [1, 2, false] }],
            [{ font: [] }],
            [{ align: [] }],
          ],
        },
      },
      mostrarNuevoFormulario: false,
      nuevoFormulario: {
        frm_predefinido_id: null,
        titulo: "",
        contenido: "",
        firma: "",
      },
      signaturePad: null,
      firma: "",
      medicoSelected: null,
      guardando: false,
      imprimiendo: false,
    };
  },
  planTratamientoService: null,
  created() {
    this.planTratamientoService = new PlanTratamientoService();
  },
  methods: {
    guardarFrm() {
      this.guardando = true;
      let datos = {};
      if (this.mostrarNuevoFormulario) {
        //el medico es obligatorio
        if (!this.medicoSelected) {
          this.$toast.add({
            severity: "error",
            summary: "Formulario Consentimiento",
            detail: "Seleccione un médico",
            life: 3000,
          });
          this.guardando = false;
          return;
        }
        datos = {
          cliente_id: this.data_cliente_id,
          frm_predefinido_id: this.nuevoFormulario.frm_predefinido_id,
          medico_id: this.medicoSelected,
          nombre_formulario: this.nuevoFormulario.titulo,
          contenido: this.nuevoFormulario.contenido,
          firma: this.signaturePad
            ? this.signaturePad.toDataURL("image/png")
            : "",
        };
      } else {
        datos = {
          cliente_id: this.data_cliente_id,
          frm_predefinido_id: this.formularioSelected.id,
          medico_id: this.formularioSelected.medico_id,
          nombre_cliente: this.formularioSelected.nombre_cliente,
          nombre_medico: this.formularioSelected.nombre_medico,
          nombre_formulario: this.formularioSelected.nombre_formulario,
          contenido: this.formularioSelected.contenido,
          firma: this.formularioSelected.firma || "",
        };
      }
      this.planTratamientoService.guardarFormulario(datos).then((response) => {
        if (response.success == false) {
          this.$toast.add({
            severity: "error",
            summary: "Formulario Consentimiento",
            detail: response.message,
            life: 3000,
          });
          this.guardando = false;
        } else {
          this.$toast.add({
            severity: "success",
            summary: "Formulario Consentimiento",
            detail: response.message,
            life: 3000,
          });
          this.$emit("actualizarListado");
          this.mostrarNuevoFormulario = false;
          this.guardando = false;
          this.mostrarModal = false;
        }
      });
    },
    imprimirFormulario() {
      this.imprimiendo = true;
      let datos = {
        cliente_id: this.data_cliente_id,
        frm_predefinido_id: this.formularioSelected
          ? this.formularioSelected.frm_predefinido_id
          : null,
      };
      this.planTratamientoService.imprimirFrmConcentimiento(datos).then(() => {
        this.imprimiendo = false;
      });
    },
    crearNuevoFormulario(formulario) {
      this.formularioSelected = null;
      this.mostrarNuevoFormulario = true;
      if (formulario) {
        this.nuevoFormulario.titulo = formulario.titulo;
        this.nuevoFormulario.frm_predefinido_id = formulario.id;
        this.cargarQuillEditor(formulario.contenido);
        this.cargarCanvas();
      }
    },
    mostrarFormularioSeleccionado(frmpredefinido) {
      this.mostrarNuevoFormulario = false;
      this.formularioSelected = frmpredefinido;
      if (frmpredefinido) {
        this.cargarQuillEditor(frmpredefinido.contenido);
      }
    },
    cargarQuillEditor(contenido) {
      this.$nextTick(() => {
        const quillEditor = this.$refs.quillEditor;

        if (quillEditor && quillEditor.getQuill) {
          // Verifica si el contenido es un string y conviértelo a JSON si es necesario
          if (typeof contenido === "string") {
            try {
              contenido = JSON.parse(contenido);
              //me aseguro que de bien el enter en el contenido
              contenido = contenido.map((item) => {
                if (item.insert === null) {
                  item.insert = ""; // Reemplaza null por una cadena vacía
                }
                return item;
              });
            } catch (error) {
              console.error("Error al parsear el contenido:", error);
              return;
            }
          }

          if (Array.isArray(contenido)) {
            contenido = contenido.map((item) => {
              if (item.insert === "") {
                item.insert = "\n"; // Reemplaza las cadenas vacías por saltos de línea
              }
              return item;
            });

            // Cargar el contenido en el editor Quill
            quillEditor.getQuill().setContents(contenido);
          } else {
            console.error("Contenido no es un array válido:", contenido);
          }
        } else {
          console.error("Quill editor no está disponible");
        }
      });
    },
    cargarCanvas() {
      nextTick(() => {
        const canvasElement = this.$refs.canvas;
        this.signaturePad = new SignaturePad(canvasElement);
      });
    },
    ocultarDialog() {
      this.mostrarModal = false;
    },
    limpiarFirma() {
      // Limpiar la firma actual
      this.signaturePad.clear();
      // Reinicializar la instancia de SignaturePad con el canvas existente
      this.signaturePad = new SignaturePad(this.$refs.canvas);
      // Limpiar la variable de firma en tu componente
      this.firma = "";
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    datos_frmprefedinidos(val) {
      this.listado_frmpredefinidos = val;
    },
    medicos(val) {
      this.listado_medicos = val;
    },

    formulario_predefinidos(val) {
      this.listado_formularios = val;
    },
    cliente_id(val) {
      this.data_cliente_id = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

ul {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
}

.formulario-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin-bottom: 10px;
  color: #333;
}

.formulario-name {
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 5px;
  border: 2px solid #ddd; /* Borde completo más grueso */
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}
.canvas {
  //que sea mas delgado
  border: 0.5px solid #002236;
}
</style>
